@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import '~@fortawesome/fontawesome-free/css/all.css';
body,
html {
  color: #000000;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

input[type=text], input[type=number], input[type=mail], .form-select {
  background-color: #ffffff;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 14px !important;
  font-weight: 400;
  color: #000000 !important;
  padding: 0px 6px;
  opacity: 1;
  align-self: stretch;
  order: 2;
  min-height: 35px;
  max-height: 48px;
  flex-grow: 1;
  width: auto;
  z-index: 4;
  box-shadow: none;
}

label.form-label {
  white-space: pre-wrap;
  overflow: visible;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 1.4;
  border-radius: 0px;
  opacity: 1;
  align-self: stretch;
  min-width: 0px;
  min-height: 0px;
  height: max-content;
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
  margin: 0px;
}

.content-login {
  display: flex;
  flex-direction: row;
}
.content-login .left-login {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
}
.content-login .right-login {
  width: 65%;
  min-height: 100vh;
  background-image: url("../images/login.jpg");
  background-size: contain;
}

h1.signtitle {
  white-space: pre-wrap;
  overflow: visible;
  font-family: "Poppins", sans-serif;
  font-size: 38px;
  font-weight: 500;
  color: #000000;
  line-height: 1.3;
  border-radius: 0px;
  opacity: 1;
  align-self: stretch;
  height: max-content;
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
  margin: 0px;
}

button.btn.btn-fh {
  background-color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 1;
  border-radius: 5px;
  min-height: 45px;
  width: 100%;
  opacity: 1;
  border: 1px solid #000000;
}
button.btn.btn-fh:hover {
  box-shadow: 0px 7px 30px -10px #000000 !important;
}

button.btn.btn-fh.add {
  width: 150px;
}

.site-header {
  background-color: #000000;
  overflow: visible;
  padding: 10px 24px;
  opacity: 1;
  min-width: 0px;
  order: 4;
  min-height: 0px;
  max-height: 72px;
  width: calc(100% + 0px);
  margin: 0px;
  display: flex;
  justify-content: space-between;
}
.site-header .site-logo {
  align-content: center;
}
.site-header .site-logo img {
  width: 200px;
}
.site-header .site-navigation {
  align-content: center;
}
.site-header .site-navigation ul, .site-header .site-navigation li {
  margin: 0;
  padding: 0;
}
.site-header .site-navigation li {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.site-header .site-navigation li a {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 1;
  padding: 0px 12px;
  text-decoration: none;
}
.site-header .site-navigation li a:active, .site-header .site-navigation li a:focus, .site-header .site-navigation li a.active {
  font-size: 18px !important;
  font-weight: 600 !important;
}
.site-header .site-logout {
  align-content: center;
}
.site-header .site-logout i {
  color: #ffffff;
  font-size: 22px;
}

.titre {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  line-height: 1.4;
  text-align: center;
}

.btn-none {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  line-height: 1.4;
  border-radius: 0px;
  background-color: transparent;
  border: none;
  padding-left: 25px;
}
.btn-none i.fa-plus {
  color: rgb(49, 178, 49);
  padding-right: 15px;
}

.espace {
  padding-left: 15px;
  color: #000000 !important;
}

.tb-pers tr {
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.tb-pers td {
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 1.4;
}
.tb-pers td a {
  color: #000000 !important;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 1.5rem !important;
}

.bt {
  display: flex;
  justify-content: center;
}

div.cnx {
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgb(49, 178, 49);
}

div.cnxOff {
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgba(136, 136, 136, 0.8);
}

.modal-content {
  padding: 20px;
  min-width: 550px;
}
.modal-content .modal-header {
  border: none;
}
.modal-content .modal-body .form-input {
  display: flex;
}
.modal-content .modal-body .form-input label {
  width: 40%;
}
.modal-content .modal-body .form-input .form-check-input {
  min-height: 13px !important;
}
.modal-content .modal-body .form-input input, .modal-content .modal-body .form-input .form-select {
  min-height: 35px !important;
  text-align: center;
}
.modal-content .modal-body .form-input .labelN {
  width: 30% !important;
}
.modal-content .modal-footer {
  border: none;
  padding-top: 0;
  display: flex;
  justify-content: center;
}
.modal-content .modal-footer button.btn.btn-fh {
  width: 150px;
}

.sale {
  width: 250px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #000000;
}
@media (max-width: 765) {
  .sale {
    width: auto;
  }
}

.sl-point {
  line-height: 3rem;
  align-items: center;
  justify-content: center;
}
.sl-point .cnxOff {
  width: 15px !important;
  height: 15px !important;
  border-radius: 15px;
  background-color: rgba(136, 136, 136, 0.8);
}
.sl-point .cnx {
  width: 15px !important;
  height: 15px !important;
  border-radius: 15px;
  background-color: rgb(49, 178, 49);
}

.content-sale {
  height: 300px;
  align-items: center;
}

.mm {
  margin-left: 1.5rem;
}

.form-check-input {
  min-height: 13px !important;
}

.space-between {
  justify-content: space-between;
}

.titre2 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  line-height: 1.4;
  text-align: center;
}

.t-not {
  width: 90%;
}

.button-disabled {
  background-color: #ccc !important; /* Couleur gris clair */
  color: #888 !important; /* Couleur gris foncé */
  cursor: not-allowed !important; /* Curseur "interdit" */
}

input[readonly] {
  background-color: #e9ecef; /* Light gray color */
}

.m {
  margin-right: 20px;
}

.modalN, .headerN {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.tcenter {
  justify-content: center;
}

.d-sale {
  margin-left: 20px;
}

.txt .esp {
  padding-right: 10px;
  font-size: 22px;
}

i.fa-solid.fa-wifi {
  padding-right: 10px;
  font-size: 20px;
}

.icone {
  width: 50px;
  height: 50px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}

.icone-on {
  width: 50px;
  height: 50px;
  background-color: green;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}

.power {
  max-width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.po {
  margin-top: 10px;
}

.card-title .titre {
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.bg-dark {
  color: #ffffff;
}

.page-x .row {
  padding: 0;
  width: 100%;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.p-power {
  min-width: 50%;
}

.line {
  background-color: rgba(136, 136, 136, 0.4);
  border-radius: 5px;
  min-width: 80%;
  max-width: 80%;
  min-height: 2px;
  margin: auto;
  margin-bottom: 1rem;
}

button.btn.btn-fhOn {
  background-color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  line-height: 1;
}

button.btn.btn-fhOff {
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
  text-align: center;
  line-height: 1;
}

.pr-3 {
  padding-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 0px;
  bottom: 0px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #000000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #000000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.fa-male {
  font-size: 80px;
}

.male {
  color: #ccc;
}

.card-bd {
  min-height: 250px;
}

.pp {
  padding: 6px;
}

.bg-dark {
  background-color: #000000 !important;
}

@media (max-width: 768px) {
  .card-titl {
    flex-direction: column;
    text-align: center;
  }
  .xcenter {
    display: flex;
    justify-content: center;
  }
  .modal-content {
    min-width: 100%;
  }
  ngb-modal-window.d-block.modal.fade.show {
    display: flex;
    align-content: center;
  }
  .sll {
    flex-direction: column;
  }
  .sale {
    width: 189px;
    margin-right: 2rem;
  }
  .slpt {
    justify-content: space-between;
  }
  .content-login .left-login {
    width: 100%;
    padding-top: 45%;
  }
  .content-login .right-login {
    display: none;
  }
  .content-login h1.signtitle {
    text-align: center;
  }
  .scrollable-div {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;
  }
  .scrollable-div::-webkit-scrollbar {
    width: 12px;
  }
  .scrollable-div::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  .scrollable-div::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }
  .scrollable-div::-webkit-scrollbar-thumb:hover {
    background: #000000;
  }
  .scrollable-div {
    scrollbar-width: thin;
    scrollbar-color: #000000 #f1f1f1;
  }
  .form-input {
    flex-direction: column;
  }
  .modal-content .modal-body .form-input label {
    width: 100%;
  }
  .cin {
    margin-bottom: 1rem;
  }
  .salex {
    width: auto !important;
    margin-left: 2rem;
    margin-right: 0 !important;
  }
  .ctr {
    text-align: center;
  }
}
@media (max-width: 1020px) {
  .sll {
    flex-direction: column;
  }
  .sale {
    width: 189px;
    margin-right: 2rem;
  }
  .slpt {
    justify-content: space-between;
  }
}
.mml {
  margin-left: 1rem;
}

.mmr {
  margin-right: 1rem;
}

.w-40 {
  width: 40%;
}

@media (min-width: 1020px) {
  .w-40, .w-40x {
    width: 40%;
  }
}